const FILTER = "customer_annual_target_filter" as string;
import type { ICustomerAnnualTargetFilterDatas } from "@/interfaces/target.interface";

/**
 * @description get customer annual target filters form sessionStorage
 */
export const getCustomerAnnualTargetFilter =
  (): ICustomerAnnualTargetFilterDatas | null => {
    const filter = window.sessionStorage.getItem(FILTER);
    return filter ? JSON.parse(filter) : null;
  };

/**
 * @description save customer annual target filters sessionStorage
 * @param filter: ICustomerAnnualTargetFilterDatas
 */
export const saveCustomerAnnualTargetFilter = (
  filter: ICustomerAnnualTargetFilterDatas | null
): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove customer annual target filters form sessionStorage
 */
export const destroyCustomerAnnualTargetFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default {
  getCustomerAnnualTargetFilter,
  saveCustomerAnnualTargetFilter,
  destroyCustomerAnnualTargetFilter,
};

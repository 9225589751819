import ActivityFilterService from "@/core/services/ActivityFilterService";
import JwtService from "@/core/services/JwtService";
import ProjectFilterService from "@/core/services/ProjectFilterService";
import PurchaseOrderFormService from "@/core/services/PurchaseOrderFormService";
import QuoteFilterService from "@/core/services/QuoteFilterService";
import SpecialPricesFilterService from "@/core/services/SpecialPricesFilterService";
import RepAnnualTargetFilterService from "@/core/services/RepAnnualTargetFilterService";
import AnnualTargetFilterService from "@/core/services/AnnualTargetFilterService";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";
import { usePofStore } from "@/stores/pof";
import { useUtilityStore } from "@/stores/utility";
import { useWebsocketStore } from "@/stores/websocket";
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/test-report",
        name: "test",
        meta: {
          pageTitle: "Report TEST",
          breadcrumbs: ["Report TEST"],
          requiresAuth: true,
          isPermissionControl: false,
        },
        component: () => import("@/views/reports/superset-reports/index.vue"),
      },
      {
        path: "/",
        name: "mainpage",
        meta: {
          pageTitle: "Mainpage",
          breadcrumbs: ["Mainpage"],
          requiresAuth: true,
          isPermissionControl: false,
        },
        component: () => import("@/views/mainpage/index.vue"),
      },
      {
        path: "/dashboards/:id",
        name: "dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/dictionary",
        name: "dictionary",
        component: () => import("@/views/dictionary/index.vue"),
        meta: {
          pageTitle: "Dictionary",
          breadcrumbs: ["Dictionary"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/storage",
        name: "storage",
        component: () => import("@/views/storage/index.vue"),
        meta: {
          pageTitle: "Storage",
          breadcrumbs: ["Storage"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expenses",
        name: "expenses",
        component: () => import("@/views/expense/index.vue"),
        meta: {
          pageTitle: "Expenses",
          breadcrumbs: ["Expenses"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expenses/add",
        name: "expenses-add",
        component: () => import("@/views/expense/add/index.vue"),
        meta: {
          pageTitle: "Add Expense",
          breadcrumbs: ["Add Expense"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expenses/edit/:id",
        name: "expenses-edit",
        component: () => import("@/views/expense/edit/index.vue"),
        meta: {
          pageTitle: "Edit Expense",
          breadcrumbs: ["Edit Expense"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expenses/view/:id",
        name: "expenses-view",
        component: () => import("@/views/expense/view/index.vue"),
        meta: {
          pageTitle: "View Expense",
          breadcrumbs: ["View Expense"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/activities",
        name: "activities",
        component: () => import("@/views/activities/index.vue"),
        meta: {
          pageTitle: "Activities",
          breadcrumbs: ["Activities"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/activities/add",
        name: "activities-add",
        component: () => import("@/views/activities/add/index.vue"),
        meta: {
          pageTitle: "Add Activity",
          breadcrumbs: ["Add Activity"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/activities/edit/:id",
        name: "activities-edit",
        component: () => import("@/views/activities/edit/index.vue"),
        meta: {
          pageTitle: "Edit Activity",
          breadcrumbs: ["Edit Activity"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/activities/view/:id",
        name: "activities-view",
        component: () => import("@/views/activities/view/index.vue"),
        meta: {
          pageTitle: "Activity View",
          breadcrumbs: ["Activity View"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/quotes",
        name: "quotes",
        component: () => import("@/views/quotes/index.vue"),
        meta: {
          pageTitle: "Quotes",
          breadcrumbs: ["Quotes"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/quotes/add",
        name: "quotes-add",
        component: () => import("@/views/quotes/add/index.vue"),
        meta: {
          pageTitle: "Add Quote",
          breadcrumbs: ["Add Quote"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/quotes/revision",
        name: "quotes-revision",
        component: () => import("@/views/quotes/revision/index.vue"),
        meta: {
          pageTitle: "Rrevision Quote",
          breadcrumbs: ["Rrevision Quote"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/quotes/edit/:id",
        name: "quotes-edit",
        component: () => import("@/views/quotes/edit/index.vue"),
        meta: {
          pageTitle: "Edit Quote",
          breadcrumbs: ["Edit Quote"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/quotes/view/:id",
        name: "quotes-view",
        component: () => import("@/views/quotes/view/index.vue"),
        meta: {
          pageTitle: "Quote View",
          breadcrumbs: ["Quote View"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/projects",
        name: "projects",
        component: () => import("@/views/projects/index.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumbs: ["Projects"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/projects/add",
        name: "projects-add",
        component: () => import("@/views/projects/add/index.vue"),
        meta: {
          pageTitle: "Add Project",
          breadcrumbs: ["Add Project"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/projects/edit/:id",
        name: "projects-edit",
        component: () => import("@/views/projects/edit/index.vue"),
        meta: {
          pageTitle: "Edit Project",
          breadcrumbs: ["Edit Project"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/projects/view/:id",
        name: "projects-view",
        component: () => import("@/views/projects/view/index.vue"),
        meta: {
          pageTitle: "Project View",
          breadcrumbs: ["Project View"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/index.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Users"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/users/add",
        name: "users-add",
        component: () => import("@/views/users/add/index.vue"),
        meta: {
          pageTitle: "Add User",
          breadcrumbs: ["Add User"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },

      {
        path: "/users/edit/:id",
        name: "user-edit",
        component: () => import("@/views/users/edit/index.vue"),
        meta: {
          pageTitle: "Edit User",
          breadcrumbs: ["Edit User"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/users/profile/:id",
        name: "user-profile",
        component: () => import("@/views/users/view/index.vue"),
        meta: {
          pageTitle: "User Profile",
          breadcrumbs: ["User Profile"],
          requiresAuth: true,
          isPermissionControl: true,
        },
        children: [
          {
            path: "general",
            name: "user-profile-general",
            component: () => import("@/views/users/profile/general.vue"),
            meta: {
              pageTitle: "User Profile / General",
              requiresAuth: true,
              isPermissionControl: true,
            },
          },
          {
            path: "kpi-metrics",
            name: "user-profile-kpi-metrics",
            component: () => import("@/views/users/profile/kpiMetrics.vue"),
            meta: {
              pageTitle: "User Profile / KPI Metrics",
              requiresAuth: true,
              isPermissionControl: true,
            },
          },
          {
            path: "kpi-assessment",
            name: "user-profile-kpi-assessment",
            component: () => import("@/views/users/profile/kpiAssessment.vue"),
            meta: {
              pageTitle: "User Profile / KPI Assessment",
              requiresAuth: true,
              isPermissionControl: true,
            },
          },
        ],
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/customers/index.vue"),
        meta: {
          pageTitle: "Customers",
          breadcrumbs: ["Customers"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/logo-customers",
        name: "logo-customers",
        component: () => import("@/views/logo-customers/index.vue"),
        meta: {
          pageTitle: "Logo Customers",
          breadcrumbs: ["Logo Customers"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/logo-customers/edit/:id",
        name: "logo-customer-edit",
        component: () => import("@/views/logo-customers/edit/index.vue"),
        meta: {
          pageTitle: "Logo Customers",
          breadcrumbs: ["Logo Customers"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/logo-customers/view/:id",
        name: "logo-customer-view",
        component: () => import("@/views/logo-customers/view/index.vue"),
        meta: {
          pageTitle: "Logo Customers",
          breadcrumbs: ["Logo Customers"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/logo-products",
        name: "logo-products",
        component: () => import("@/views/logo-products/index.vue"),
        meta: {
          pageTitle: "Logo Products",
          breadcrumbs: ["Logo Products"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/logo-products/edit/:id",
        name: "logo-product-edit",
        component: () => import("@/views/logo-products/edit/index.vue"),
        meta: {
          pageTitle: "Logo Products",
          breadcrumbs: ["Logo Products"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/logo-products/view/:id",
        name: "logo-product-view",
        component: () => import("@/views/logo-products/view/index.vue"),
        meta: {
          pageTitle: "Logo Products",
          breadcrumbs: ["Logo Products"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/lines",
        name: "lines",
        component: () => import("@/views/lines/index.vue"),
        meta: {
          pageTitle: "Lines",
          breadcrumbs: ["Lines"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expo",
        name: "expo",
        component: () => import("@/views/expo/index.vue"),
        meta: {
          pageTitle: "Expo",
          breadcrumbs: ["Expo"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expo/add",
        name: "expo-add",
        component: () => import("@/views/expo/add/index.vue"),
        meta: {
          pageTitle: "Add Expo",
          breadcrumbs: ["Add Expo"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expo/:parent_id/plans",
        name: "expo-plans",
        component: () => import("@/views/expo/plans/index.vue"),
        meta: {
          pageTitle: "Expo Plans",
          breadcrumbs: ["Expo Plans"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expo/:parent_id/reports",
        name: "expo-reports",
        component: () =>
          import("@/views/expo/components/ExpoReportListPage.vue"),
        meta: {
          pageTitle: "Expo Reports",
          breadcrumbs: ["Expo Reports"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expo/:parent_id/reports/update/:id",
        name: "expo-reports-edit",
        component: () => import("@/views/expo/reports/index.vue"),
        meta: {
          pageTitle: "Expo Update",
          breadcrumbs: ["Expo Update"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/expo/:parent_id/media",
        name: "expo-media",
        component: () =>
          import("@/views/expo/components/ExpoMediaListPage.vue"),
        meta: {
          pageTitle: "Expo Media",
          breadcrumbs: ["Expo Media"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/samples",
        name: "samples",
        component: () => import("@/views/samples/index.vue"),
        meta: {
          pageTitle: "Samples",
          breadcrumbs: ["Samples"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/samples/add",
        name: "samples-add",
        component: () => import("@/views/samples/add/index.vue"),
        meta: {
          pageTitle: "Add Sample",
          breadcrumbs: ["Add Sample"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/samples/edit/:id",
        name: "samples-edit",
        component: () => import("@/views/samples/edit/index.vue"),
        meta: {
          pageTitle: "Sample Edit",
          breadcrumbs: ["Sample Edit"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/samples/view/:id",
        name: "samples-view",
        component: () => import("@/views/samples/view/index.vue"),
        meta: {
          pageTitle: "Sample View",
          breadcrumbs: ["Sample View"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/sync/customer",
        name: "customer-sync",
        component: () => import("@/views/sync/customer/index.vue"),
        meta: {
          pageTitle: "Customer Sync",
          breadcrumbs: ["Customer Sync"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/sync/product",
        name: "product-sync",
        component: () => import("@/views/sync/product/index.vue"),
        meta: {
          pageTitle: "Product Sync",
          breadcrumbs: ["Product Sync"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/calendar/index.vue"),
        meta: {
          pageTitle: "Calendar",
          breadcrumbs: ["Calendar"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/iot",
        name: "iot",
        component: () => import("@/views/iot/index.vue"),
        meta: {
          pageTitle: "IoT",
          breadcrumbs: ["IoT"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/tickets",
        name: "tickets",
        component: () => import("@/views/tickets/index.vue"),
        meta: {
          pageTitle: "Tickets",
          breadcrumbs: ["Tickets"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/customer-support",
        name: "technology-supports-view",
        component: () => import("@/views/customer-support/index.vue"),
        meta: {
          pageTitle: "Technology Supports",
          breadcrumbs: ["Technology Supports"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/tickets/:id",
        name: "tickets-view",
        component: () => import("@/views/tickets/view/index.vue"),
        meta: {
          pageTitle: "Tickets Details",
          breadcrumbs: ["Tickets Details"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/notification/index.vue"),
        meta: {
          pageTitle: "Notifications",
          breadcrumbs: ["Notifications"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/reports/ACTIVITY-ADMIN-UNCOMPLETED-REPORT/",
        name: "activity-admin-uncompleted-report",
        component: () => import("@/views/reports/activity/index.vue"),
        meta: {
          pageTitle: "Activity Admin Reports Data",
          breadcrumbs: ["Activity Admin Reports Data"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "repots/MARKETING-PERFORMANCE-REPORT/",
        name: "marketing-performancereport",
        component: () => import("@/views/reports/performance/index.vue"),
        meta: {
          pageTitle: "Marketing Performance Report",
          breadcrumbs: ["Marketing Performance Report"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/annual-target",
        name: "annual-target",
        component: () => import("@/views/targets/index.vue"),
        meta: {
          pageTitle: "Annual Target",
          breadcrumbs: ["Annual Target"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/annual-target/edit/:id",
        name: "annual-target-edit",
        component: () => import("@/views/targets/edit/index.vue"),
        meta: {
          pageTitle: "Update Annual Target",
          breadcrumbs: ["Update Annual Target"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/approvals",
        name: "approvals",
        component: () => import("@/views/approvals/index.vue"),
        meta: {
          pageTitle: "Approvals",
          breadcrumbs: ["Approvals"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/kpi-management",
        name: "kpi-management",
        component: () => import("@/views/kpi-management/index.vue"),
        meta: {
          pageTitle: "Kpi Management",
          breadcrumbs: ["Kpi Management"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/kpi-marketing-management",
        name: "kpi-marketing-management",
        component: () => import("@/views/kpi-marketing-management/index.vue"),
        meta: {
          pageTitle: "Marketing Team Management",
          breadcrumbs: ["Marketing Team Management"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/business-card/:name",
        name: "business-card",
        component: () => import("@/views/business-card/index.vue"),
        meta: {
          pageTitle: "Business Card",
          breadcrumbs: ["Business Card"],
          requiresAuth: !!JwtService.getToken(),
          isPermissionControl: false,
        },
      },
      {
        path: "/changelog",
        name: "changelog",
        component: () => import("@/views/changelog/index.vue"),
        meta: {
          pageTitle: "Changelog",
          breadcrumbs: ["Changelog"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/purchase-orders",
        name: "purchase-orders",
        component: () => import("@/views/pof/index.vue"),
        meta: {
          pageTitle: "Purchase & Quote Tracking",
          breadcrumbs: ["Purchase & Quote Tracking"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/purchase-orders/add",
        name: "purchase-order-add",
        component: () => import("@/views/pof/add/index.vue"),
        meta: {
          pageTitle: "Add Purchase & Quote Tracking",
          breadcrumbs: ["Add Purchase & Quote Tracking"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/purchase-orders/edit/:id",
        name: "purchase-order-edit",
        component: () => import("@/views/pof/edit/index.vue"),
        meta: {
          pageTitle: "Edit Purchase & Quote Tracking",
          breadcrumbs: ["EditPurchase & Quote Tracking"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/purchase-orders/view/:id",
        name: "purchase-orders-view",
        component: () => import("@/views/pof/view/index.vue"),
        meta: {
          pageTitle: "Purchase & Quote Tracking View",
          breadcrumbs: ["Purchase & Quote Tracking View"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/inventory",
        name: "inventory",
        component: () => import("@/views/inventory/index.vue"),
        meta: {
          pageTitle: "Inventory Information",
          breadcrumbs: ["Inventory Information"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/special-prices/add",
        name: "special-price-add",
        component: () =>
          import("@/views/inventory/special-prices/add/index.vue"),
        meta: {
          pageTitle: "Add Special Price",
          breadcrumbs: ["Add Special Price"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/special-prices/edit/:id",
        name: "special-prices-edit",
        component: () =>
          import("@/views/inventory/special-prices/edit/index.vue"),
        meta: {
          pageTitle: "Edit Special Price",
          breadcrumbs: ["Edit Special Price"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/quote-tracking",
        name: "quote-tracking",
        component: () =>
          import("@/views/quote-tracking/index.vue"),
        meta: {
          pageTitle: "Quote Tracking",
          breadcrumbs: ["Quote Tracking"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/rep-annual-target",
        name: "rep-annual-target",
        component: () =>
          import("@/views/rep-target/index.vue"),
        meta: {
          pageTitle: "Rep Annual Target",
          breadcrumbs: ["Rep Annual Target"],
          requiresAuth: true,
          isPermissionControl: true,
        },
      },
      {
        path: "/rep-annual-target/edit/:id",
        name: "rep-annual-target-edit",
        component: () => import("@/views/rep-target/edit/index.vue"),
        meta: {
          pageTitle: "Update Rep Annual Target",
          breadcrumbs: ["Update Rep Annual Target"],
          requiresAuth: true,
          isPermissionControl: true,
        },
        children: [
          {
            path: "values",
            name: "rep-annual-target-values",
            component: () => import("@/views/rep-target/lines/values/index.vue"),
            meta: {
              pageTitle: "Update Rep Annual Target / Values",
              requiresAuth: true,
              isPermissionControl: true,
            },
          },
          {
            path: "line-annual-targets",
            name: "rep-annual-target-line",
            component: () => import("@/views/rep-target/lines/annual/index.vue"),
            meta: {
              pageTitle: "Update Rep Annual Target / Line Annual Targets",
              requiresAuth: true,
              isPermissionControl: true,
            },
          },
          {
            path: "customer-monthly-targets",
            name: "rep-annual-target-customer",
            component: () => import("@/views/rep-target/lines/monthly/index.vue"),
            meta: {
              pageTitle: "Update Rep Annual Target / Customer Monthly Targets",
              requiresAuth: true,
              isPermissionControl: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
          isPermissionControl: false,
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
        meta: {
          pageTitle: "Forgot Password",
          breadcrumbs: ["Forgot Password"],
          isPermissionControl: false,
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/authentication/PasswordReset.vue"),
        meta: {
          pageTitle: "Password Reset",
          breadcrumbs: ["Password Reset"],
          isPermissionControl: false,
        },
        props: (route) => ({ token: route.query.token }),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
          requiresAuth: !!JwtService.getToken(),
          isPermissionControl: false,
        },
      },
      {
        path: "/maintenance",
        name: "under-maintenance",
        component: () => import("@/views/authentication/UnderMaintenance.vue"),
        meta: {
          pageTitle: "Under Maintenance",
          requiresAuth: !!JwtService.getToken(),
          isPermissionControl: false,
        },
      },
    ],
  },
  {
    // File Download Page
    path: "/shared/:url_token",
    name: "storage-file-download",
    meta: {
      pageTitle: "Download File",
      requiresAuth: !!JwtService.getToken(),
      isPermissionControl: false,
    },
    component: () => import("@/views/storage/download/index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: {
      pageTitle: "404",
      requiresAuth: !!JwtService.getToken(),
      isPermissionControl: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const utilityStore = useUtilityStore();
  const configStore = useConfigStore();
  const pofStore = usePofStore();
  const wsStore = useWebsocketStore();

  let permissions = [];

  // current page view title
  document.title = `${import.meta.env.VITE_APP_NAME} - ${to.meta.pageTitle}`;

  if (JwtService.getToken()) {
    utilityStore.getAppInfo();

    try {
      const response = await authStore.userMePermissions();
      permissions = response.page_permissions;
    } catch (e) {}

    try {
      await wsStore.sendPageView(to?.meta?.pageTitle, to?.params?.id ?? null);
    } catch (e) {
      console.log("Websocket Pageview Error", e);
    }
  }

  if (!to.path.includes("quotes")) {
    QuoteFilterService.destroyQuoteFilter();
    window.sessionStorage.removeItem("is_create_pof");
    pofStore.resetPofProducts();
  }

  if (!to.path.includes("activities")) {
    ActivityFilterService.destroyActivityFilter();
  }

  if (!to.path.includes("projects")) {
    ProjectFilterService.destroyProjectFilter();
  }

  if (!to.path.includes("purchase-orders")) {
    PurchaseOrderFormService.destroyPurchaseOrderFormFilter();
  }

  if (!to.path.includes("inventory") && !to.path.includes("special-prices")) {
    SpecialPricesFilterService.destroySpecialPricesFilter();
  }
  
  if (!to.path.includes("rep-annual-target")) {
    RepAnnualTargetFilterService.destroyRepAnnualTargetFilter();
  }

  if (!to.path.includes("annual-target")) {
    AnnualTargetFilterService.destroyCustomerAnnualTargetFilter();
  }

  // reset config to initial state
  configStore.resetLayoutConfig();

  if (to.matched.some((record) => record.meta.isPermissionControl)) {
    const routerName = to?.name as never;

    if (!permissions?.includes(routerName)) {
      return next("/404");
    }
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !JwtService.getToken()
  ) {
    return next("/sign-in");
  } else if (
    !to.matched.some((record) => record.meta.requiresAuth) &&
    JwtService.getToken()
  ) {
    return next("/");
  }

  next();

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;

const FILTER = "rep_annual_target_filter" as string;
import type { IRepAnnualTargetFilterDatas } from "@/interfaces/rep-target.interface";

/**
 * @description get rep annual target filters form sessionStorage
 */
export const getRepAnnualTargetFilter =
  (): IRepAnnualTargetFilterDatas | null => {
    const filter = window.sessionStorage.getItem(FILTER);
    return filter ? JSON.parse(filter) : null;
  };

/**
 * @description save rep annual target filters sessionStorage
 * @param filter: IRepAnnualTargetFilterDatas
 */
export const saveRepAnnualTargetFilter = (
  filter: IRepAnnualTargetFilterDatas | null
): void => {
  window.sessionStorage.setItem(FILTER, JSON.stringify(filter));
};

/**
 * @description remove rep annual target filters form sessionStorage
 */
export const destroyRepAnnualTargetFilter = (): void => {
  window.sessionStorage.removeItem(FILTER);
};

export default {
  getRepAnnualTargetFilter,
  saveRepAnnualTargetFilter,
  destroyRepAnnualTargetFilter,
};
